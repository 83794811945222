

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  background: aliceblue;
}

body {
  background: aliceblue;
}

.gtco-testimonials {
  position: relative;
  margin-top: 30px;

  h2 {
    font-size: 30px;
    text-align: center;
    color: #333333;
    margin-bottom: 50px;
  }

  .owl-stage-outer {
    padding: 30px 0;
  }

  .owl-nav {
    display: none;
  }

  .owl-dots {
    text-align: center;

    span {
      position: relative;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: block;
      background: #fff;
      border: 2px solid #01b0f8;
      margin: 0 5px;
    }

    .active {
      box-shadow: none;

      span {
        background: black;
        box-shadow: none;
        height: 12px;
        width: 12px;
        margin-bottom: -1px;
      }
    }
  }
.card-body{
  padding-top: 5px;
}
  .card {
    background: #fff;
    box-shadow: 0 8px 30px -7px #c9dff0;
    margin: 0 20px;
    padding: 10px;
    border-radius: 20px;
    border: 0;

    .card-img-top {
      max-width: 70px;
      border-radius: 50%;
      margin: 15px auto 0;
      box-shadow: 0 8px 20px -4px #95abbb;
      width: 70px;
      height: 70px;
    }

    h5 {
      color: black;
      font-size: 21px;
      line-height: 1.3;

      span {
        font-size: 18px;
        color: #666666;
      }
    }

    p {
      font-size: 18px;
      color: #555;
      padding-bottom: 15px;
    }
  }

  .active {
    opacity: 0.5;
    transition: all 0.3s;
  }

  .center {
    opacity: 1;

    h5 {
      font-size: 20px;
      color: #c09d3e;

      span {
        font-size: 20px;
      }
    }

    .card-img-top {
      max-width: 100%;
      height: 100px;
      width: 100px;
    }
  }
}

@media (max-width: 767px) {
  .gtco-testimonials {
    margin-top: 20px;
  }
}

.owl-carousel {
  .owl-nav button {
    &.owl-next, &.owl-prev {
      outline: 0;
    }
  }

  button.owl-dot {
    outline: 0;
  }
}
.certificate-slide {
  display: flex;
  justify-content: center;
}

.certificateSlider{
  display: flex;
  justify-content: center;
  /* height: 90%; */
  /* width: 700%; */
  border: 1px solid red;
}

.owl-carousel  img {
  width: 20%;
  height: 20%;
}
.owl-item{
display: flex;
justify-content: center;
align-items: center;

img {
  height: fit-content;
}

}

.card-text{
  padding: 0px;
}
.star-ratings{
  padding: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.owl-themes{
  width: 700px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.demo{ background: #262526; }
.our-team{
    padding: 20px 15px 30px;
    background: #fff;
    border-radius: 15px;
    text-align: center;
}
.our-team .pic{
    display: inline-block;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 10px;
    margin-bottom: 25px;
    transition: all 0.5s ease 0s;
}
.our-team:hover .pic{
    background: #c09d3e;
    border-radius: 50%;
}
.pic img{
    width: 100%;
    height: auto;
    border-radius: 50%;
}
.our-team .title{
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: #2e282a;
    text-transform: uppercase;
    margin: 0 0 7px 0;
}
.our-team .post{
    display: block;
    font-size: 15px;
    color: #c09d3e;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.our-team .social{
    padding: 0;
    margin: 0;
    list-style: none;
}
.our-team .social li{
    display: inline-block;
    margin-right: 5px;
}
.our-team .social li a{
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    font-size: 15px;
    color: #17bebb;
    border: 1px solid #c09d3e;
    transition: all 0.5s ease 0s;
}
.our-team:hover .social li a{
    background: #c09d3e;
    color: #fff;
}
@media only screen and (max-width: 990px){
    .our-team{ margin-bottom: 30px; }
}

.no-underline {
  text-decoration: none;
}

.text-inherit {
  color: inherit;
}

.zoom-box img {
  width: 100%;
  height: auto;
}

.raise-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.card-description.expanded {
  -webkit-line-clamp: unset;
}
