@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@700&family=Imprima&display=swap');


body {
  margin: 0;
  font-family: 'Imprima', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cinzel {
  font-family: 'Cinzel Decorative', cursive !important;
}

.imprima {
  font-family: 'Imprima', sans-serif !important;
}
